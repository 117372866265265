<template>
  <app-navbar />
  <main>
    <router-view />
  </main>
  <app-footer />
</template>

<script>
import AppNavbar from '@/components/nav/Navbar';
import AppFooter from '@/components/nav/Footer';

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppFooter,
  },
  async created() {
    await this.$store.dispatch('initLogin');
  },
};
</script>

<style lang="scss">
main {
  background-color: $dark-bg;
  padding-top: 2rem;
}
</style>