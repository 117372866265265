<template>
  <header class="header" v-if="!isTextLoading">
    <div class="container-fluid">
      <nav class="navbar-container">
        <router-link :to="{ name: 'Home' }" class="navbar-logo">
          <img
            :src="require(`@/assets/img/${texts.logo.desktop}`)"
            class="navbar-brand desktop-logo"
            :alt="texts.logo.alt"
          />
          <img
            :src="require(`@/assets/img/${texts.logo.mobile}`)"
            class="navbar-brand mobile-logo"
            :alt="texts.logo.alt"
          />
        </router-link>
        <ul
          class="navbar-menu"
          :class="{ active: isActive }"
          @click="isActive = !isActive"
        >
          <template v-if="userLoggedIn">
            <li
              class="navbar-item"
              v-for="item in texts.userLoggedIn"
              :key="item.key"
            >
              <router-link
                v-if="item.key !== 'logout'"
                class="navbar-item__btn"
                :to="{ name: item.route }"
              >
                <span class="material-symbols-outlined"> {{ item.icon }} </span
                >{{ item.label }}
              </router-link>
              <a href="#" v-else class="navbar-item__btn" @click="handleLogout">
                <span class="material-symbols-outlined"> {{ item.icon }} </span
                >{{ item.label }}
              </a>
            </li>
          </template>
          <li class="navbar-item" v-else>
            <router-link class="navbar-item__btn" :to="{ name: 'Login' }">
              <span class="material-symbols-outlined">
                {{ texts.userLoggedOut.icon }} </span
              >{{ texts.userLoggedOut.label }}
            </router-link>
          </li>
        </ul>
        <div
          class="hamburger"
          @click="isActive = !isActive"
          :class="{ active: isActive }"
        >
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import Utilities from '@/utils/services/Utilities';
import useTexts from '@/mixins/useTexts';

export default {
  name: 'Navbar',
  mixins: [useTexts],
  data() {
    return {
      page: 'navbar',
      isActive: false,
    };
  },
  computed: {
    userLoggedIn() {
      return this.$store.state.auth.userLoggedIn;
    },
  },
  methods: {
    async handleLogout() {
      const status = await this.$store.dispatch('logout');

      if (status !== 204) return Utilities.showMessage('error', 'logout_error');

      this.$router.push({ name: 'Home' });
    },
  },
};
</script>
