<template>
  <div class="scroll-top" @click="scrollToTop" v-if="showScroll">
    <i class="bi bi-chevron-up"></i>
  </div>
</template>

<script>
export default {
  name: 'ScrollToTop',
  data() {
    return {
      showScroll: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.checkScroll);
  },
  methods: {
    checkScroll() {
      let windowScroll = document.documentElement.scrollTop;
      const scroll = windowScroll > 300 ? true : false;
      this.showScroll = scroll;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss">
.scroll-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  border: 2px solid $dark-grey;
  background-color: $dark-grey;
  border-radius: 3px;
  cursor: pointer;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $trs-default;
  i {
    font-size: 1.5rem;
    color: $light;
  }
  &:hover {
    background-color: $secondary;
    border-color: $secondary;
    i {
      color: #fff;
    }
  }
}
</style>
