import Home from '@/views/Home.vue';

// add type for after account creation
const types = {
  success: [
    {
      type: 'email_verified',
      path: 'confirmation-du-compte',
    },
    {
      type: 'verify_email',
      path: 'verification-email'
    },
    {
      type: 'reset_password',
      path: 'mot-de-passe',
    },
    {
      type: 'forgot_password',
      path: 'reinitialisation',
    },
    {
      type: 'registration',
      path: 'inscription',
    },
  ],
  error: [
    {
      type: 'email_verified',
      path: 'confirmation-du-compte',
    },
    {
      type: 'verify_email',
      path: 'verification-email'
    },
    {
      type: 'reset_password',
      path: 'mot-de-passe',
    },
    {
      type: 'forgot_password',
      path: 'reinitialisation',
    },
    {
      type: 'not_found',
      path: '404',
    },
  ]
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/connexion',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../../views/auth/Login.vue'),
  },
  {
    path: '/inscription',
    name: 'Register',
    component: () =>
      import(
        /* webpackChunkName: "register" */ '../../views/auth/Register.vue'
      ),
  },
  {
    path: '/reinitialisation',
    name: 'Password',
    component: () =>
      import(
        /* webpackChunkName: "password" */ '../../views/auth/Password.vue'
      ),
  },
  {
    path: '/politique-de-confidentialite',
    name: 'Privacy',
    component: () =>
      import(/* webpackChunkName: "privacy" */ '../../views/Privacy.vue'),
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: () =>
      import(
        /* webpackChunkName: "documentation" */ '../../views/Documentation.vue'
      ),
  },
  {
    path: '/s/:path',
    name: 'Success',
    component: () =>
      import(
        /* webpackChunkName: "success" */ '../../views/layout/Success.vue'
      ),
  },
  {
    path: '/e/:path',
    name: 'Error',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "error" */ '../../views/layout/Error.vue'),
  },
  {
    path: '/t/confirmation-du-compte/:token',
    name: 'EmailToken',
    component: () =>
      import(
        /* webpackChunkName: "email-token" */ '../../views/tokens/Email.vue'
      ),
  },
  {
    path: '/t/mot-de-passe/:token',
    name: 'PasswordToken',
    component: () =>
      import(
        /* webpackChunkName: "password-token" */ '../../views/tokens/Password.vue'
      ),
  },
  {
    path: '/mon-compte',
    name: 'UserProfile',
    component: () =>
      import(/* webpackChunkName: "profile" */ '../../views/user/Profile.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mes-fdes',
    name: 'UserReports',
    component: () =>
      import(/* webpackChunkName: "reports" */ '../../views/user/Reports.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/configurateur/etape-1',
    name: 'Configurator_Step1',
    component: () =>
      import(
        /* webpackChunkName: "configurator-step1" */ '../../views/tool/Step_1.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/configurateur/etape-2',
    name: 'Configurator_Step2',
    component: () =>
      import(
        /* webpackChunkName: "configurator-step2" */ '../../views/tool/Step_2.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/configurateur/etape-3',
    name: 'Configurator_Step3',
    component: () =>
      import(
        /* webpackChunkName: "configurator-step3" */ '../../views/tool/Step_3.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:catchAll(.*)*',
    redirect: { name: 'Error', params: { path: '404' } },
  },
];

export { types, routes };
