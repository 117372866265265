import Utilities from '@/utils/services/Utilities';

export default {
  data() {
    return {
      texts: {},
      isTextLoading: false,
    };
  },
  async created() {
    this.isTextLoading = true;
    this.texts = await Utilities.getPageTexts(this.page);
    this.isTextLoading = false;
  },
};
