<template>
  <div class="container" v-if="!isTextLoading">
    <div class="row">
      <div class="col-12">
        <h1 class="mb-4">{{ texts.title }}</h1>
        <p v-if="!userLoggedIn" :v-html="texts.intro"></p>
      </div>
      <div class="row home-steps-row mt-5">
        <div
          class="col-12 col-lg-4 home-steps-row__col"
          v-for="step in texts.steps"
          :key="step.title"
        >
          <app-card :title="step.title" :body="step.body" :icon="step.icon" />
        </div>
        <div class="col-12 text-center mb-4">
          <button
            class="btn"
            @click="
              $router.push({
                name: userLoggedIn
                  ? texts.button.loggedInRoute
                  : texts.button.loggedOutRoute,
              })
            "
          >
            {{ texts.button.text }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid home-img-container mt-2">
    <div class="img-row row">
      <div
        class="col-12 col-md-4 g-0 img-row__item"
        v-for="image in texts.images"
        :key="image.img"
      >
        <img :src="require(`@/assets/img/${image.img}`)" />
      </div>
    </div>
  </div>
  <scroll-to-top />
</template>

<script>
import ScrollToTop from '@/components/nav/ScrollToTop.vue';
import useTexts from '@/mixins/useTexts';

export default {
  name: 'HomePage',
  components: {
    ScrollToTop,
  },
  mixins: [useTexts],
  data() {
    return {
      page: 'home',
    };
  },
  computed: {
    userLoggedIn() {
      return this.$store.state.auth.userLoggedIn;
    },
  },
};
</script>

<style lang="scss">
.home-steps-row {
  &__col {
    margin-bottom: 2rem;
    @include bp-down(lg) {
      margin-bottom: 3rem;
    }
  }
}

.home-img-container {
  padding-right: 0;
  padding-left: 0;
  .img-row {
    padding-top: 1rem;
    display: flex;
    &__item {
      object-fit: fit-content;
      padding: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
