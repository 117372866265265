<template>
  <div class="row form-row">
    <el-form :model="formValues" label-position="top">
      <div class="row">
        <template v-for="(item, index) in formCopy" :key="index">
          <div :class="item?.size">
            <el-form-item :label="getLabel(item)">
              <component
                v-if="item.type !== 'image'"
                :is="item.component"
                v-model="formValues[item.name]"
                :placeholder="item.placeholder"
                :type="item.type"
                :multiple="item?.multiple"
                :clearable="item.type === 'select' ? true : null"
                :disabled="item?.is_disabled"
                size="large"
                @blur="handleBlur(item)"
                @change="item.type === 'select' ? validateField(item.name) : ''"
              >
                <template v-if="item.type === 'checkbox'">{{
                  item.label
                }}</template>
                <template v-if="item.type === 'select'">
                  <el-option
                    v-for="option in item.options"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  />
                </template>
                <template #suffix v-if="item?.is_password">
                  <show-password
                    :index="index"
                    :activated="activePassword === index"
                    @togglePassword="togglePassword"
                  />
                </template>
              </component>
            </el-form-item>
          </div>

          <div class="form-error" v-if="formErrors[item.name].length">
            <p
              v-for="(error, errIndex) in formErrors[item.name]"
              :key="errIndex"
            >
              {{ error }}
            </p>
          </div>
        </template>
        <slot name="text" />

        <div class="btn-group mt-2 mb-2">
          <button class="btn" @click.prevent="handleSubmit">{{ button }}</button>
          <slot name="button" />

        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import Validator from '@/utils/services/Validator';
import Utilities from '@/utils/services/Utilities';
import ShowPassword from '@/components/items/ShowPassword';

export default {
  name: 'AppForm',
  props: ['model', 'form', 'rules', 'itemToUpdate', 'button', 'buttonSide'],
  emits: ['submitted'],
  components: {
    ShowPassword,
  },
  data() {
    return {
      formValues: this.model,
      formErrors: {},
      activePassword: null,
      formCopy: this.form,
      route: '',
      showSeparator: false,
    };
  },
  created() {
    this.route = this.$route.name;
    let isEmpty = this.itemToUpdate
      ? Object.keys(this.itemToUpdate).length === 0
      : true;
    
    if (!isEmpty) {
      Object.keys(this.model).forEach((key) => {
        this.formValues[key] = this.itemToUpdate[key];
      });
    }

    const keys = Object.keys(this.model);
    for (let i in keys) this.formErrors[keys[i]] = [];
  },
  methods: {
    isSeparator(name) {
      this.showSeparator = false;
      if (this.route === 'Register' && name === 'confirm_password') {
        this.showSeparator = true;
      }
    },
    handleSubmit() {
      const isValid = Validator.validateForm(this.formValues, this.rules);

      if (isValid) this.$emit('submitted', this.formValues);
      else Utilities.showMessage('error', 'empty_fields_error');
    },
    validateField(fieldName) {
      const value = this.formValues[fieldName];
      const rules = this.rules[fieldName];

      let errors = [];

      for (let i in rules) {
        if (rules[i].type === 'confirm') {
          rules[i].comparedValue = this.formValues[rules[i].confirmField];
        }
        const error = Validator.validateField(value, rules[i]);
        if (error) errors.push(error);
      }

      this.formErrors[fieldName] = errors.slice(-1);
    },
    handleBlur(field) {
      if (field.type === 'select') return;

      this.validateField(field.name);
    },
    handlePaste(fieldName) {
      if (
        fieldName === 'email' ||
        fieldName === 'password' ||
        fieldName === 'new_password' ||
        fieldName === 'confirm_password'
      ) {
        this.formValues[fieldName] = '';
        Utilities.showMessage('warning', 'copy_paste_error');
      }
    },
    getLabel(item) {
      const isRequired = item.required && item.type !== 'checkbox' ? '*' : '';
      const label = item.type === 'checkbox' ? ' ' : item.label;
      return `${label} ${isRequired}`;
    },
    togglePassword(type, index) {
      this.activePassword = index;

      this.formCopy[index] = {
        ...this.formCopy[index],
        type: type === 'show' ? 'text' : 'password',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;
}

.btn-group {
  max-width: 80%;
  margin: 0 auto;
}
</style>
