<template>
  <div
    class="card-static"
    :class="`text-${textAlign}`"
    @click="$emit('clicked')"
  >
    <div class="card-img">
      <span class="material-symbols-outlined">
        {{ icon }}
      </span>
    </div>
    <div class="card-header">
      <h4 class="card-title">{{ title }}</h4>
    </div>
    <div class="card-body">{{ body }}</div>
    <div class="card-footer"><slot name="footer" /></div>
  </div>
</template>

<script>
// add icon option
export default {
  name: 'AppCard',
  props: {
    textAlign: {
      type: String,
      default: 'center',
    },
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    route: {
      type: String,
      default: '',
    },
  },
  emits: ['clicked'],
};
</script>
