<template>
  <footer class="container-fluid" v-if="!isTextLoading">
    <div class="footer-container">
      <span class="footer-copyright text-dark">© {{ year }} Meiser</span>
      <div class="footer-links">
        <span v-for="link in texts.links" :key="link.title">
          <router-link
            v-if="link.type === 'internal'"
            :to="{ name: link.link }"
            >{{ link.title }}</router-link
          >
          <a v-else :href="link.link" target="_blank">{{ link.title }}</a> |
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
// TODO: mobile design
import useTexts from '@/mixins/useTexts';

export default {
  name: 'AppFooter',
  mixins: [useTexts],
  data() {
    return {
      year: new Date().getFullYear(),
      page: 'footer',
    };
  },
};
</script>

<style lang="scss">
footer {
  background-color: $light-grey;
  font-size: 0.8rem;
  .footer-container {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    .footer-copyright {
      width: 30%;
    }
    .footer-links {
      width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  }
}
</style>
